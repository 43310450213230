import React from 'react';
import { calcDSpacingOfCubicPlan } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import { getInitialVector, renderOutput, displayIndicesLiteral } from '../Vectors/Vector';
import { Typehkl, Typeuvw } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';
import { PlanOutput } from '../Outputs';

type InputType = Typehkl & {
  a: number
}

type OutputType = Typeuvw & {
  d: number
}

const getInitialInput = (value: number): InputType => {
  return {
    a: value,
    h: value,
    k: value,
    l: value
  };
};

const getInitialOutput = (value: number): OutputType => {
  return {
    d: value,
    u: value,
    v: value,
    w: value
  };
};

const inputVector = getInitialVector('hkl');
const outputVector = getInitialVector('uvw');

const CubicPlanNormal: CalculatorConfig<InputType, OutputType> = {
  title: <>Normal Direction, {displayIndicesLiteral(outputVector)}, and d-spacing of the Plan In the Cubic System</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: [inputVector],
    LPs: ['a']
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    vectors: [outputVector],
    preventDefaultRender: true,
    render: (outputValue, vectors) => <PlanOutput
      planNormal={vectors !== undefined ? renderOutput(vectors[0], outputValue) : ''}
      dSpacing={outputValue.d.toFixed(2)}
      />
  },
  calculate: (input: InputType) => {
    const { h, k, l, a } = input;
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVector,
        value: [h, k, l]
      }
    ]);
    return errors !== null
      ? errors
      : {
          u: h,
          v: k,
          w: l,
          d: calcDSpacingOfCubicPlan([h, k, l], a)
        };
  }
};

export default CubicPlanNormal;
