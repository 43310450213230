import React from 'react';
import { Form } from 'semantic-ui-react';
import IndexInput from '../Inputs/IndexInput';

export type VectorType = 'uvw' | 'UVTW' | 'hkl' | 'hkil';

export type ValueType = {
  [key: string]: number
}

type onChangeType = (name: string, value: number) => void

type IndexType = {
  x: string
  y: string
  z: string
}

export interface IVector {
  vectorType: VectorType
  index: IndexType
  subfix: string
}

export const getInitialVector = (vectorType: VectorType, subfix: string = ''): IVector => {
  switch (vectorType) {
    case 'hkl':
      return {
        vectorType: 'hkl',
        subfix,
        index: {
          x: 'h' + subfix,
          y: 'k' + subfix,
          z: 'l' + subfix
        }
      };
    case 'hkil':
      return {
        vectorType: 'hkil',
        subfix,
        index: {
          x: 'h' + subfix,
          y: 'k' + subfix,
          z: 'l' + subfix
        }
      };
    case 'uvw':
      return {
        vectorType: 'uvw',
        subfix,
        index: {
          x: 'u' + subfix,
          y: 'v' + subfix,
          z: 'w' + subfix
        }
      };
    case 'UVTW':
      return {
        vectorType: 'UVTW',
        subfix,
        index: {
          x: 'U' + subfix,
          y: 'V' + subfix,
          z: 'W' + subfix
        }
      };
  }
};

export const isHexMBVector = (vectorType: VectorType): boolean => vectorType === 'hkil' || vectorType === 'UVTW';

export const isHexPlanVectorType = (vectorType: VectorType): boolean => vectorType === 'hkl' || vectorType === 'hkil';

export const renderOutput = (vector: IVector, state: ValueType): JSX.Element => {
  const left = isHexPlanVectorType(vector.vectorType) ? '(' : '[';
  const right = isHexPlanVectorType(vector.vectorType) ? ')' : ']';
  const { x, y, z } = vector.index;
  let values = [state[x], state[y], state[z]];
  if (isHexMBVector(vector.vectorType)) {
    values = [values[0], values[1], -values[0] - values[1], values[2]];
  }
  const output: string[] = values.map(value => Number.isInteger(value) ? String(value) : value.toFixed(2));
  return (
    <span key={x}>
      {left + output.join(', ') + right}
    </span>
  );
};

export const renderInput = (vector: IVector, states: ValueType, onChange: onChangeType): JSX.Element => {
  const { vectorType, subfix, index } = vector;
  let disabledValue: number;
  let indices: string[];
  switch (vectorType) {
    case 'hkil':
      indices = [index.x, index.y, 'i' + subfix, index.z];
      disabledValue = -states[index.x] - states[index.y];
      break;
    case 'UVTW':
      indices = [index.x, index.y, 'T' + subfix, index.z];
      disabledValue = -states[index.x] - states[index.y];
      break;
    default:
      indices = [index.x, index.y, index.z];
  }

  return (
    <span key={vectorType + subfix}>
      <Form.Group widths='equal'>
        {indices.map((value) => {
          if (value in states) {
            return (
              <IndexInput
                key={value}
                label={value}
                value={states[value]}
                onChange={onChange}
              />
            );
          } else {
            return (
              <IndexInput
                key={value}
                label={value}
                value={disabledValue}
                disabled
              />
            );
          }
        })}
      </Form.Group>
    </span>);
};

export const displayIndicesLiteral = (vector: IVector): React.ReactNode => {
  const { vectorType, subfix } = vector;
  const left = isHexPlanVectorType(vectorType) ? '(' : '[';
  const right = isHexPlanVectorType(vectorType) ? ')' : ']';
  return (
    <>
      {left}
      <span>
        {vectorType.split('').map(value => (
          <i key={value}>
            {value}
            <sub>{subfix}</sub>
          </i>
        ))}
      </span>
      {right}
    </>
  );
};
