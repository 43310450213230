type Coordinate = {
  x: number
  y: number
}

export type DrawHexConfig = {
  hexSize: number
  lineWidth: number
  fillStyle: string
  stepDuration: number
  strokeStyle: string
}

const getHexVertexes = (centerPoint: Coordinate, hexSize: number): Coordinate[] => {
  const centerX = centerPoint.x;
  const centerY = centerPoint.y;
  const partialLength = (hexSize / 2) * Math.sqrt(3) / 3;
  return [
    {
      x: centerX,
      y: centerY - 2 * partialLength
    },
    {
      x: centerX + 0.5 * hexSize,
      y: centerY - partialLength
    },
    {
      x: centerX + 0.5 * hexSize,
      y: centerY + partialLength
    },
    {
      x: centerX,
      y: centerY + 2 * partialLength
    },
    {
      x: centerX - 0.5 * hexSize,
      y: centerY + partialLength
    },
    {
      x: centerX - 0.5 * hexSize,
      y: centerY - partialLength
    },
    {
      x: centerX,
      y: centerY - 2 * partialLength
    }
  ];
};

interface IDraw {
  draw: (delay: number) => void
  cancelDraw: () => void
}

export class DrawHex implements IDraw {
  ctx: CanvasRenderingContext2D;
  centerPoint: Coordinate;
  config: DrawHexConfig;
  requestID: number = 0;
  constructor (ctx: CanvasRenderingContext2D, centerPoint: Coordinate, config: DrawHexConfig) {
    this.ctx = ctx;
    this.centerPoint = centerPoint;
    this.config = config;
  }

  draw (delay: number = 0): void {
    const { hexSize, lineWidth, fillStyle, stepDuration, strokeStyle } = this.config;
    const ctx = this.ctx;
    const vertexes = getHexVertexes(this.centerPoint, hexSize);
    if (stepDuration === 0) {
      ctx.beginPath();
      ctx.strokeStyle = strokeStyle;
      ctx.moveTo(vertexes[0].x, vertexes[0].y);
      for (let i = 1; i <= 5; i++) {
        ctx.lineTo(vertexes[i].x, vertexes[i].y);
      }
      ctx.closePath();
      ctx.fillStyle = fillStyle;
      ctx.fill();
      return;
    };
    let startPoint = vertexes[0];
    let endPoint = vertexes[1];
    const duration = stepDuration;
    let totalStartTime: number;
    let linesDrawn = 0;
    let prevX = startPoint.x;
    let prevY = startPoint.y;
    let nextX: number;
    let nextY: number;
    let startTime: number;

    const step = (currentTime: number): void => {
      if (totalStartTime === undefined) totalStartTime = currentTime;
      if (startTime === undefined) startTime = currentTime;
      if (currentTime - totalStartTime < delay) {
        startTime = currentTime;
        this.requestID = requestAnimationFrame(step);
        return;
      };
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      const draw = (): void => {
        ctx.lineWidth = lineWidth;
        ctx.beginPath();
        ctx.moveTo(prevX, prevY);
        prevX = nextX = startPoint.x + (endPoint.x - startPoint.x) * progress;
        prevY = nextY = startPoint.y + (endPoint.y - startPoint.y) * progress;
        ctx.lineTo(nextX, nextY);
        ctx.stroke();
      };

      const fill = (): void => {
        ctx.beginPath();
        const points = getHexVertexes(this.centerPoint, hexSize * progress);
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i <= 5; i++) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.closePath();
        ctx.fillStyle = fillStyle;
        ctx.fill();
      };

      if (linesDrawn < 6) {
        draw();
      } else {
        fill();
      }

      if (progress < 1) {
        this.requestID = requestAnimationFrame(step);
      } else {
        linesDrawn = Math.floor((currentTime - totalStartTime - delay) / duration);
        if (linesDrawn < 6) {
          startTime = currentTime;
          startPoint = vertexes[linesDrawn];
          endPoint = vertexes[(linesDrawn + 1) % 6];
          this.requestID = requestAnimationFrame(step);
        } else if (linesDrawn === 6) {
          startTime = currentTime;
          this.requestID = requestAnimationFrame(step);
        };
      }
    };
    this.requestID = requestAnimationFrame(step);
  }

  cancelDraw (): void {
    cancelAnimationFrame(this.requestID);
  }
}

export default DrawHex;
