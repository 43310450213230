import React, { useState, useEffect } from 'react';
import { calculators } from '../Calculator/Configs';
import { useAppContext } from '../../context/appContext';
import { Button, Header, Menu, Sidebar, Segment } from 'semantic-ui-react';
import DesktopSidebar from './Navbar/DesktopSidebar';
import HomeBackground from './HomeBackground';

const Home: React.FC = () => {
  const [currentCalculator, setCurrentCalculator] = useState<JSX.Element | null>(null);
  const [selectedCalculatorIndex, selectCalculator] = useState<number>(-1);
  const { showSidebar, toggleSidebar } = useAppContext();
  const [visible, setVisible] = useState(false);

  const navTriggerHandler = (): void => {
    toggleSidebar();
    setVisible(prev => !prev);
  };

  const handleNavItemClick = (index: number): void => {
    selectCalculator(index);
    const NewCalculator = calculators[index].Component;
    setCurrentCalculator(<NewCalculator />);
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent): void => {
      if (event.key === ' ') {
        event.preventDefault();
        navTriggerHandler();
      }
      if (showSidebar && selectedCalculatorIndex > 0 && (event.key === 'ArrowLeft' || event.key === 'ArrowUp')) {
        selectCalculator(selectedCalculatorIndex - 1);
        const NewCalculator = (calculators[selectedCalculatorIndex - 1]).Component;
        setCurrentCalculator(<NewCalculator/>);
      }
      if (showSidebar && selectedCalculatorIndex < calculators.length - 1 && (event.key === 'ArrowRight' || event.key === 'ArrowDown')) {
        selectCalculator(selectedCalculatorIndex + 1);
        const NewCalculator = calculators[selectedCalculatorIndex + 1].Component;
        setCurrentCalculator(<NewCalculator/>);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [showSidebar, selectedCalculatorIndex]);

  return (
    <>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation='push'
          icon='labeled'
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width='thin'
        >
          <DesktopSidebar
            selectedCalculatorIndex={selectedCalculatorIndex}
            onNavItemClick={handleNavItemClick}
          />
        </Sidebar>
        <Sidebar.Pusher>
          <Segment basic style={{ height: '100vh', overflowY: 'auto' }}>
            <Topbar navTriggerHandler={navTriggerHandler} />
            {currentCalculator}
            <HomeBackground />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default Home;

function Topbar ({ navTriggerHandler }: { navTriggerHandler: () => void }): JSX.Element {
  return (
    <Menu attached='top'>
      <Menu.Item>
        <Button
          icon='bars'
          basic
          onClick={navTriggerHandler}
        />
      </Menu.Item>
      <Menu.Item position='right'>
        <Header as='h3'>Crystallographic Calculator</Header>
      </Menu.Item>
    </Menu>
  );
}
