import React from 'react';
import { calculators } from '../../Calculator/Configs';
import { Menu } from 'semantic-ui-react';

const DesktopSidebar: React.FC<{ selectedCalculatorIndex: number, onNavItemClick: (selectedIndex: number) => void }> = ({ selectedCalculatorIndex, onNavItemClick }) => {
  return (
    <>
      {calculators.map((calculator, index) => (
      <Menu.Item
        active={selectedCalculatorIndex === index}
        key={index}
        onClick={() => onNavItemClick(index)}
      >
        {calculator.name}
      </Menu.Item>
      ))}
   </>
  );
};

export default DesktopSidebar;
