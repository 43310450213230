import React from 'react';
import { getInitialhkl, Typehkl } from './ConfigTypes';
import { CalculatorConfig } from '../Calculator';
import { displayIndicesLiteral, getInitialVector } from '../Vectors/Vector';

const inputVector = getInitialVector('hkl');
const outputVector = getInitialVector('hkil');

const hkl2hkil: CalculatorConfig<Typehkl, Typehkl> = {
  title: <>Hexagonal Miller Plan {displayIndicesLiteral(inputVector)} → Hexagonal Miller-Bravais Plan {displayIndicesLiteral(outputVector)}</>,
  input: {
    getInitialValue: () => getInitialhkl(0),
    vectors: [inputVector]
  },
  output: {
    getInitialValue: () => getInitialhkl(0),
    vectors: [outputVector]
  },
  calculate: (input: Typehkl) => {
    return { ...input };
  }
};

export default hkl2hkil;
