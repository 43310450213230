const arrUVW2uvw = (UVW: number[]): number[] => {
  const [U, V, W] = UVW;
  return [2 * U + V, 2 * V + U, W];
};

const arruvw2UVW = (uvw: number[]): number[] => {
  const [u, v, w] = uvw;
  let res = [2 * u - v, 2 * v - u, 3 * w];
  if (res.every(v => v % 3 === 0)) res = res.map(v => v / 3);
  return res;
};

const calcDSpacingOfHexPlan = (hkl: number[], ac: number[]): number => {
  const [h, k, l] = hkl;
  const [a, c] = ac;
  const A = (h ** 2 + h * k + k ** 2) / a ** 2;
  const B = 4 / 3 * A + (l / c) ** 2;
  return B ** (-0.5);
};

const calcHexPlanNormal = (hkl: number[], ac: number[]): number[] => {
  const [h, k, l] = hkl;
  const [a, c] = ac;
  let N = 0;
  if (c !== undefined) {
    N = l * (3 * a ** 2) / (2 * c ** 2);
  }
  return [h, k, N];
};

const calcDSpacingOfCubicPlan = (hkl: number[], a: number): number => {
  const [h, k, l] = hkl;
  const A = h ** 2 + k ** 2 + l ** 2;
  return a * (A ** -0.5);
};

const calcCosThetaDir = (dir1: number[], dir2: number[], ac: number[]): number => {
  const [u1, v1, w1] = dir1;
  const [u2, v2, w2] = dir2;
  const [a, c] = ac;
  const A = a ** 2 * (u1 * u2 + v1 * v2 - 0.5 * (u1 * v2 + v1 * u2)) + c ** 2 * w1 * w2;
  const B = a ** 2 * (u1 ** 2 - u1 * v1 + v1 ** 2) + (c * w1) ** 2;
  const C = a ** 2 * (u2 ** 2 - u2 * v2 + v2 ** 2) + (c * w2) ** 2;
  return A / (B ** 0.5 * C ** 0.5);
};

const calcCosThetaPlan = (plan1: number[], plan2: number[], ac: number[]): number => {
  const [h1, k1, l1] = plan1;
  const [h2, k2, l2] = plan2;
  const [a, c] = ac;
  const D = 3 * a ** 2 / (4 * c ** 2);
  const A = h1 * h2 + k1 * k2 + 0.5 * (h1 * k2 + h2 * k1) + l1 * l2 * D;
  const B = h1 ** 2 + k1 ** 2 + h1 * k1 + D * l1 ** 2;
  const C = h2 ** 2 + k2 ** 2 + h2 * k2 + D * l2 ** 2;
  return A / ((B * C) ** 0.5);
};

const calcCosTHetaDirCubic = (dir1: number[], dir2: number[]): number => {
  const [u1, v1, w1] = dir1;
  const [u2, v2, w2] = dir2;
  const A = u1 * u2 + v1 * v2 + w1 * w2;
  const B = u1 ** 2 + v1 ** 2 + w1 ** 2;
  const C = u2 ** 2 + v2 ** 2 + w2 ** 2;
  return A * (B * C) ** -0.5;
};

export { arruvw2UVW, arrUVW2uvw, calcCosThetaDir, calcCosThetaPlan, calcDSpacingOfHexPlan, calcDSpacingOfCubicPlan, calcCosTHetaDirCubic, calcHexPlanNormal };
