import React from 'react';
import { calcCosThetaDir, arrUVW2uvw } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import ThetaOutput from '../Outputs/ThetaOutput';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { Typeac, TypeUVW, Sub } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';

type InputType = Typeac & Sub<TypeUVW, '1'> & Sub<TypeUVW, '2'>

type OutputType = {
  cosTheta: number
}

const getInitialInput = (value: number): InputType => {
  return {
    a: value,
    c: value,
    U1: value,
    V1: value,
    W1: value,
    U2: value,
    V2: value,
    W2: value
  };
};

const getInitialOutput = (value: number): OutputType => {
  return {
    cosTheta: value
  };
};

const inputVectors = [getInitialVector('UVTW', '1'), getInitialVector('UVTW', '2')];

const HexDirsAngleUVTW: CalculatorConfig<InputType, OutputType> = {
  title: <>Angles between two Hexagonal Directions, {displayIndicesLiteral(inputVectors[0])} and {displayIndicesLiteral(inputVectors[1])}</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: inputVectors,
    LPs: ['a', 'c']
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    render: ({ cosTheta }) => {
      return <ThetaOutput cosTheta={cosTheta}/>;
    }
  },
  calculate: (input: InputType) => {
    const { U1, V1, W1, U2, V2, W2, a, c } = input;
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVectors[0],
        value: [U1, V1, W1]
      },
      {
        type: 'vector',
        vector: inputVectors[1],
        value: [U2, V2, W2]
      },
      {
        type: 'ac',
        value: [a, c]
      }
    ]);
    return errors !== null
      ? errors
      : {
          cosTheta: calcCosThetaDir(arrUVW2uvw([U1, V1, W1]), arrUVW2uvw([U2, V2, W2]), [a, c])
        };
  }
};

export default HexDirsAngleUVTW;
