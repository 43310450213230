import React from 'react';
import { calcCosTHetaDirCubic } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import ThetaOutput from '../Outputs/ThetaOutput';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { Typeuvw, Sub } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';

type InputType = Sub<Typeuvw, '1'> & Sub<Typeuvw, '2'>

type OutputType = {
  cosTheta: number
}

const getInitialInput = (value: number): InputType => ({
  u1: value,
  v1: value,
  w1: value,
  u2: value,
  v2: value,
  w2: value
});

const getInitialOutput = (value: number): OutputType => ({
  cosTheta: value
});

const inputVectors = [getInitialVector('uvw', '1'), getInitialVector('uvw', '2')];

const CubicDirsAngle: CalculatorConfig<InputType, OutputType> = {
  title: <>Angles between two Cubic Directions, {displayIndicesLiteral(inputVectors[0])} and {displayIndicesLiteral(inputVectors[1])}</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: inputVectors
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    render: ({ cosTheta }) => {
      return <ThetaOutput cosTheta={cosTheta}/>;
    }
  },
  calculate: (input: InputType) => {
    const { u1, v1, w1, u2, v2, w2 } = input;
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVectors[0],
        value: [u1, v1, w1]
      },
      {
        type: 'vector',
        vector: inputVectors[1],
        value: [u2, v2, w2]
      }
    ]);
    return errors !== null
      ? errors
      : {
          cosTheta: calcCosTHetaDirCubic([u1, v1, w1], [u2, v2, w2])
        };
  }
};

export default CubicDirsAngle;
