import React from 'react';
import { calcDSpacingOfHexPlan, calcHexPlanNormal } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import { getInitialVector, renderOutput, displayIndicesLiteral } from '../Vectors/Vector';
import { Typehkl, Typeac, TypeUVW } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';
import { PlanOutput } from '../Outputs';

type InputType = Typehkl & Typeac

type OutputType = TypeUVW & {
  d: number
}

const getInitialInput = (value: number): InputType => {
  return {
    a: value,
    c: value,
    h: value,
    k: value,
    l: value
  };
};

const getInitialOutput = (value: number): OutputType => {
  return {
    d: value,
    U: value,
    V: value,
    W: value
  };
};

const inputVector = getInitialVector('hkil');

const outputVector = getInitialVector('UVTW');

const HexPlanNormalUVTW: CalculatorConfig<InputType, OutputType> = {
  title: <>Normal Direction, {displayIndicesLiteral(outputVector)}, and d-spacing of the Plan In the Hexagonal System</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: [inputVector],
    LPs: ['a', 'c']
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    vectors: [outputVector],
    preventDefaultRender: true,
    render: (outputValue, vectors) => <PlanOutput
    planNormal={vectors !== undefined ? renderOutput(vectors[0], outputValue) : ''}
    dSpacing={outputValue.d.toFixed(2)}
    />
  },
  calculate: (input: InputType) => {
    const { h, k, l, a, c } = input;
    const [U, V, W] = calcHexPlanNormal([h, k, l], [a, c]);
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVector,
        value: [h, k, l]
      },
      {
        type: 'ac',
        value: [a, c]
      }
    ]);
    return errors !== null
      ? errors
      : {
          U,
          V,
          W,
          d: calcDSpacingOfHexPlan([h, k, l], [a, c])
        };
  }
};

export default HexPlanNormalUVTW;
