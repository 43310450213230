import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

interface IndexInputProps {
  label: string
  value: number
  onChange?: (name: string, value: number) => void
  disabled?: boolean
}

const IndexInput: React.FC<IndexInputProps> = ({ label, value, onChange, disabled }) => {
  const [error, setError] = useState<string | null>(null);

  let subfix: string = '';
  if (label.length > 1) {
    subfix = label.slice(1);
    label = label[0];
  }

  const handleChange = (value: number): void => {
    if (value > 100 || value < -100) {
      setError(`The index should not ${value > 100 ? 'above 100' : 'below -100'}`);
    } else {
      onChange?.(label + subfix, value);
      setError(null);
    };
  };

  return (
    <>
      <Form.Input
        type='number'
        placeholder="0"
        label={<i>{label}<sub>{subfix}</sub></i>}
        value={value === 0 ? '' : value}
        onChange={(_, { value }) => handleChange(Number(value))}
        disabled={disabled}
        error={error}
      >
        <input onKeyDown={(e) => e.code === 'KeyE' && e.preventDefault()} />
      </Form.Input>
    </>
  );
};

export default IndexInput;
