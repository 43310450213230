import React from 'react';
import { calcCosThetaDir } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import ThetaOutput from '../Outputs/ThetaOutput';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { Typeac, Typeuvw, Sub } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';

type InputType = Typeac & Sub<Typeuvw, '1'> & Sub<Typeuvw, '2'>

type OutputType = {
  cosTheta: number
}

const getInitialInput = (value: number): InputType => {
  return {
    a: value,
    c: value,
    u1: value,
    v1: value,
    w1: value,
    u2: value,
    v2: value,
    w2: value
  };
};

const getInitialOutput = (value: number): OutputType => {
  return {
    cosTheta: value
  };
};

const inputVectors = [getInitialVector('uvw', '1'), getInitialVector('uvw', '2')];

const HexDirsAngleuvw: CalculatorConfig<InputType, OutputType> = {
  title: <>Angles between two Hexagonal Directions, {displayIndicesLiteral(inputVectors[0])} and {displayIndicesLiteral(inputVectors[1])}</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: inputVectors,
    LPs: ['a', 'c']
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    render: ({ cosTheta }) => {
      return <ThetaOutput cosTheta={cosTheta}/>;
    }
  },
  calculate: (input: InputType) => {
    const { u1, v1, w1, u2, v2, w2, a, c } = input;
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVectors[0],
        value: [u1, v1, w1]
      },
      {
        type: 'vector',
        vector: inputVectors[1],
        value: [u2, v2, w2]
      },
      {
        type: 'ac',
        value: [a, c]
      }
    ]);
    return errors !== null
      ? errors
      : {
          cosTheta: calcCosThetaDir([u1, v1, w1], [u2, v2, w2], [a, c])
        };
  }
};

export default HexDirsAngleuvw;
