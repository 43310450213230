import React from 'react';
import { getInitialuvw, Typeuvw, getInitialUVW, TypeUVW } from './ConfigTypes';
import { CalculatorConfig } from '../Calculator';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { arruvw2UVW } from '../utils/math/calculation';

const inputVector = getInitialVector('uvw');
const outputVector = getInitialVector('UVTW');

const uvw2UVW: CalculatorConfig<Typeuvw, TypeUVW> = {
  title: <>Hexagonal Miller-Bravais Plan {displayIndicesLiteral(inputVector)} → Hexagonal Miller Plan {displayIndicesLiteral(outputVector)}</>,
  input: {
    getInitialValue: () => getInitialuvw(0),
    vectors: [getInitialVector('uvw')]
  },
  output: {
    getInitialValue: () => getInitialUVW(0),
    vectors: [getInitialVector('UVTW')]
  },
  calculate: (input: Typeuvw) => {
    const { u, v, w } = input;
    const [U, V, W] = arruvw2UVW([u, v, w]);
    return { U, V, W };
  }
};

export default uvw2UVW;
