import React from 'react';
import { calcCosThetaPlan } from '../utils/math/calculation';
import { CalculatorConfig } from '../Calculator';
import ThetaOutput from '../Outputs/ThetaOutput';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { Sub, Typeac, Typehkl } from './ConfigTypes';
import { validateInputs } from '../Errors/Error';

type InputType = Typeac & Sub<Typehkl, '1'> & Sub<Typehkl, '2'>

type OutputType = {
  cosTheta: number
}

const getInitialInput = (value: number): InputType => {
  return {
    a: value,
    c: value,
    h1: value,
    k1: value,
    l1: value,
    h2: value,
    k2: value,
    l2: value
  };
};

const getInitialOutput = (value: number): OutputType => {
  return {
    cosTheta: value
  };
};

const inputVectors = [getInitialVector('hkil', '1'), getInitialVector('hkil', '2')];

const HexPlansAnglehkil: CalculatorConfig<InputType, OutputType> = {
  title: <>Angles between two Hexagonal Plans, {displayIndicesLiteral(inputVectors[0])} and {displayIndicesLiteral(inputVectors[1])}</>,
  input: {
    getInitialValue: () => getInitialInput(0),
    vectors: inputVectors,
    LPs: ['a', 'c']
  },
  output: {
    getInitialValue: () => getInitialOutput(0),
    render: ({ cosTheta }) => {
      return <ThetaOutput cosTheta={cosTheta}/>;
    }
  },
  calculate: (input: InputType) => {
    const { h1, k1, l1, h2, k2, l2, a, c } = input;
    const errors = validateInputs([
      {
        type: 'vector',
        vector: inputVectors[0],
        value: [h1, k1, l1]
      },
      {
        type: 'vector',
        vector: inputVectors[1],
        value: [h2, k2, l2]
      },
      {
        type: 'ac',
        value: [a, c]
      }
    ]);
    return errors !== null
      ? errors
      : {
          cosTheta: calcCosThetaPlan([h1, k1, l1], [h2, k2, l2], [a, c])
        };
  }
};

export default HexPlansAnglehkil;
