import React from 'react';
import { getInitialuvw, Typeuvw, getInitialUVW, TypeUVW } from './ConfigTypes';
import { CalculatorConfig } from '../Calculator';
import { getInitialVector, displayIndicesLiteral } from '../Vectors/Vector';
import { arrUVW2uvw } from '../utils/math/calculation';

const inputVector = getInitialVector('UVTW');
const outputVector = getInitialVector('uvw');

const UVTW2uvw: CalculatorConfig<TypeUVW, Typeuvw> = {
  title: <>Hexagonal Miller-Bravais Plan {displayIndicesLiteral(inputVector)} → Hexagonal Miller Plan {displayIndicesLiteral(outputVector)}</>,
  input: {
    getInitialValue: () => getInitialUVW(0),
    vectors: [inputVector]
  },
  output: {
    getInitialValue: () => getInitialuvw(0),
    vectors: [outputVector]
  },
  calculate: (input: TypeUVW) => {
    const { U, V, W } = input;
    const [u, v, w] = arrUVW2uvw([U, V, W]);
    return { u, v, w };
  }
};

export default UVTW2uvw;
