import { Coordinate } from '../Components/Animations/model/Point';
import { InitialStateType } from './appContext';

type Action =
  | { type: 'TRANSFORM_READY' }
  | { type: 'TRANSFORM_DONE' }
  | { type: 'TRANSFORM_RESET' }
  | { type: 'SET_END_POINT', payload: { coordinate: Coordinate }}
  | { type: 'TOGGLE_SIDEBAR' }
  | { type: 'SELECT_CALCULATOR', payload: { selectedIndex: number } }

const reducer = (state: InitialStateType, action: Action): InitialStateType => {
  if (action.type === TRANSFORM_READY) {
    return {
      ...state,
      isTransformReady: true
    };
  }
  if (action.type === TRANSFORM_DONE) {
    return {
      ...state,
      isTransformReady: false,
      isTransformDone: true
    };
  }
  if (action.type === TRANSFORM_RESET) {
    return {
      ...state,
      isTransformReady: false,
      isTransformDone: false
    };
  }
  if (action.type === SET_END_POINT) {
    return {
      ...state,
      endPoint: action.payload.coordinate
    };
  }
  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar
    };
  }
  if (action.type === SELECT_CALCULATOR) {
    return {
      ...state,
      selectedCalculatorIndex: action.payload.selectedIndex
    };
  }
  return state;
};

export default reducer;

export const TRANSFORM_RESET = 'TRANSFORM_RESET';
export const TRANSFORM_READY = 'TRANSFORM_READY';
export const TRANSFORM_DONE = 'TRANSFORM_DONE';
export const SET_END_POINT = 'SET_END_POINT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SELECT_CALCULATOR = 'SELECT_CALCULATOR';
