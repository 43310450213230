import React from 'react';
import Canvas from '../Animations/Canvas/Canvas';
import { DrawHexBackground } from '../Animations/model/DrawHexBackground';
import { OpenAnimationConfigType } from '../Animations/OpenAnimation/openAnimationConfig';

const HomeBackgroundConfig: OpenAnimationConfigType = {
  centerDistance: 103,
  hexSize: 100,
  strokeStyle: '#2e2929',
  lineWidth: 4,
  stepDuration: 0,
  fillStyle: '#85a0a61a'
};

const HomeBackground = (): JSX.Element => {
  const draw = (width: number, height: number, ctx: CanvasRenderingContext2D): void => {
    const drawHex = new DrawHexBackground(width, height, ctx, HomeBackgroundConfig);
    drawHex.draw();
  };

  return (
    <Canvas
      draw={draw}
    />
  );
};

export default HomeBackground;
