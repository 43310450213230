import React from 'react';
import { Label, Form } from 'semantic-ui-react';

interface NumberInputProps {
  label: string
  value: number
  unit?: string
  onChange?: (name: string, value: number) => void
}

const NumberInput: React.FC<NumberInputProps> = ({ label, value, onChange, unit }) => {
  return (
    <Form.Input
      type='number'
      labelPosition='right'
      value={value === 0 ? '' : value}
      placeholder="0"
      onChange={(_, { value }) => onChange?.(label, Number(value))}
    >
      <Label basic><i>{label}</i></Label>
      <input onKeyDown={(e) => e.code === 'KeyE' && e.preventDefault()} />
      {unit !== undefined && <Label basic>{unit}</Label>}
    </Form.Input>
  );
};

export default NumberInput;
