import DrawHex, { DrawHexConfig } from './DrawHex';

export type Coordinate = {
  x: number
  y: number
}

export interface IPoint {
  setNeighbours: (neighbours: IPoint[]) => void
  getNeighbours: () => IPoint[]
  getCoordinate: () => Coordinate
  getIsDraw: () => boolean
  setIsDrawn: () => void
  draw: (delay: number) => void
  cancelDraw: () => void
}

interface IDraw {
  draw: (delay: number) => void
  cancelDraw: () => void
}

export class Point implements IPoint {
  private readonly coordinate: Coordinate;
  private readonly Draw: IDraw;
  private isDrawn: boolean = false;
  private neighbours: IPoint[] = [];
  constructor (ctx: CanvasRenderingContext2D, coordinate: Coordinate, config: DrawHexConfig) {
    this.coordinate = coordinate;
    this.Draw = new DrawHex(ctx, coordinate, config);
  };

  setNeighbours (neighbours: IPoint[]): void {
    this.neighbours = neighbours;
  };

  getNeighbours (): IPoint[] {
    return this.neighbours;
  };

  getCoordinate (): Coordinate {
    return this.coordinate;
  };

  getIsDraw (): boolean {
    return this.isDrawn;
  };

  setIsDrawn (): void {
    this.isDrawn = true;
  }

  draw (delay: number = 0): void {
    this.Draw.draw(delay);
  };

  cancelDraw (): void {
    this.Draw.cancelDraw();
  }
};
