import React from 'react';
import HexPlanNormalUVTW from './HexPlanNormalUVTW';
import HexPlanNormaluvw from './HexPlanNormaluvw';
import UVTW2uvw from './UVTW2uvw';
import uvw2UVW from './uvw2UVTW';
import hkl2hkil from './hkl2hkil';
import hkil2hkl from './hkil2hkl';
import CubicPlanNormal from './CubicNormal';
import HexDirsAngleuvw from './HexDirsAngleuvw';
import HexDirsAngleUVTW from './HexDirsAngleUVTW';
import HexPlansAnglehkl from './HexPlansAnglehkl';
import HexPlansAnglehkil from './HexPlansAnglehkil';
import CubicDirsAngle from './CubicDirsAngle';
import CubicPlansAngle from './CubicPlansAngle';
import Calculator from '../Calculator';

type CalculatorController = {
  name: string
  Component: React.ComponentType
}

export const calculators: CalculatorController[] = [
  {
    name: 'UVTW to uvw',
    Component: () => <Calculator config={UVTW2uvw}/>
  },
  {
    name: 'uvw to UVW',
    Component: () => <Calculator config={uvw2UVW}/>
  },
  {
    name: 'hkl to hkil',
    Component: () => <Calculator config={hkl2hkil}/>
  },
  {
    name: 'hkil to hkl',
    Component: () => <Calculator config={hkil2hkl}/>
  },
  {
    name: 'Plan Normal in Hex (UVTW)',
    Component: () => <Calculator config={HexPlanNormalUVTW}/>
  },
  {
    name: 'Plan Normal in Hex (uvw)',
    Component: () => <Calculator config={HexPlanNormaluvw}/>
  },
  {
    name: 'Angle between two directions in Hex (uvw)',
    Component: () => <Calculator config={HexDirsAngleuvw}/>
  },
  {
    name: 'Angle between two directions in Hex (UVTW)',
    Component: () => <Calculator config={HexDirsAngleUVTW}/>
  },
  {
    name: 'Angle between two plan normals in Hex (hkl)',
    Component: () => <Calculator config={HexPlansAnglehkl}/>
  },
  {
    name: 'Angle between two plan normals in Hex (hkil)',
    Component: () => <Calculator config={HexPlansAnglehkil}/>
  },
  {
    name: 'Plan Normal in Cubic',
    Component: () => <Calculator config={CubicPlanNormal}/>
  },
  {
    name: 'Angle between two directions in Cubic',
    Component: () => <Calculator config={CubicDirsAngle}/>
  },
  {
    name: 'Angle between two plan normals in Cubic',
    Component: () => <Calculator config={CubicPlansAngle}/>
  }
];
