import React from 'react';
import StatisticGroup from '../../GeneralSemanticUI/StatisticGroup';

export default function PlanOutput ({ planNormal, dSpacing }: { planNormal: string | JSX.Element, dSpacing: string | number }): JSX.Element {
  return (
    <StatisticGroup
    statistics={[{
      label: 'plan normal',
      value: planNormal
    },
    {
      label: 'd-spacing (Å)',
      value: dSpacing
    }]}
  />
  );
}
