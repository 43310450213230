import React, { useEffect, useRef } from 'react';

interface CanvasProps {
  draw: (width: number, height: number, ctx: CanvasRenderingContext2D) => void
}

const Canvas: React.FC<CanvasProps> = ({ draw }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const handleResize = (): void => {
      if (canvasRef.current === null) return;
      const canvas = canvasRef.current;
      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      const context = canvas.getContext('2d');
      if (context === null) return;
      draw(canvas.width, canvas.height, context);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (<canvas style={{ position: 'absolute', top: 0, left: 0 }} ref={canvasRef}/>);
};

export default Canvas;
