import React from 'react';
import { Statistic } from 'semantic-ui-react';

type StatisticProps = {
  label: string | JSX.Element
  value: number | string | JSX.Element
};

const StatisticGroup = ({ statistics }: {statistics: StatisticProps[]}): JSX.Element => {
  return (
    <Statistic.Group size='mini'>
      {statistics.map(({ label, value }: StatisticProps, index) => (
        <Statistic key={index}>
          <Statistic.Label>{label}</Statistic.Label>
          <Statistic.Value>{value}</Statistic.Value>
        </Statistic>
      ))}
    </Statistic.Group>
  );
};

export default StatisticGroup;
