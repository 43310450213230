import React from 'react';
import StatisticGroup from '../../GeneralSemanticUI/StatisticGroup';

const ThetaOutput = ({ cosTheta }: {cosTheta: number}): JSX.Element => {
  const getCosTheta = (): string => {
    return cosTheta.toFixed(2);
  };
  const getTheta = (): string => {
    const thetaRad = Math.acos(cosTheta);
    const thetaDeg = thetaRad / Math.PI * 180;
    return `${thetaDeg.toFixed(2)}° (${thetaRad.toFixed(2)} rad)`;
  };

  return (
    <StatisticGroup
      statistics={[{
        label: 'cos(θ)',
        value: getCosTheta()
      },
      {
        label: 'θ',
        value: getTheta()
      }]}
    />
  );
};

export default ThetaOutput;
