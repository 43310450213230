import React, { useState, useEffect } from 'react';
import NumberInput from './Inputs/NumberInput';
import { IVector, renderOutput, renderInput } from './Vectors/Vector';
import { Form, Segment, Button, Header, Divider, Message } from 'semantic-ui-react';

export type ValueType = {
  [key: string]: number
}

interface InputConfig<T> {
  getInitialValue: () => T
  vectors: IVector[]
  LPs?: string[]
}

interface OutputConfig<T> {
  getInitialValue: () => T
  vectors?: IVector[]
  preventDefaultRender?: boolean
  render?: (output: ValueType, vectors?: IVector[]) => JSX.Element
}

export interface CalculatorConfig<InputType extends ValueType, OutputType extends ValueType> {
  title: React.ReactNode
  input: InputConfig<InputType>
  output: OutputConfig<OutputType>
  calculate: (input: InputType) => OutputType | React.ReactNode[]
}

interface CalculatorProps<InputType extends ValueType, OutputType extends ValueType> {
  config: CalculatorConfig<InputType, OutputType>
}

export const Calculator = <InputType extends ValueType, OutputType extends ValueType>(props: CalculatorProps<InputType, OutputType>): JSX.Element => {
  const { input, output, title, calculate } = props.config;
  const [inputValue, setInputValue] = useState<InputType>(input.getInitialValue());
  const [outputValue, setOutputValue] = useState<OutputType>(output.getInitialValue());
  const [errors, setErrors] = useState<React.ReactNode[] | null>(null);
  const handleInputChange = (name: string, value: number): void => {
    setInputValue({ ...inputValue, [name]: value });
  };
  const resultIsError = (res: OutputType | React.ReactNode[]): res is React.ReactNode[] => {
    return Array.isArray(res);
  };

  const handleClacClick = (): void => {
    const res = calculate(inputValue);
    if (resultIsError(res)) {
      setErrors(res);
    } else {
      setOutputValue(res);
      setErrors(null);
    }
  };
  const handleResetClick = (): void => {
    setInputValue(input.getInitialValue());
    setOutputValue(output.getInitialValue());
    setErrors(null);
  };

  useEffect(() => {
    errors !== null && setErrors(null);
    const keyDownHandler = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleClacClick();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [inputValue]);

  return (
    <Segment>
      <Header as='h4'>{title}</Header>
      <Form>
        <Form.Group widths='equal'>
          {input?.LPs !== undefined && input.LPs.map((label) =>
            <NumberInput
            key={label}
            label={label}
            value={inputValue[label]}
            unit='Å'
            onChange={handleInputChange}
          />)}
          </Form.Group>
        {input.vectors.map((vector) => renderInput(vector, inputValue, handleInputChange))}
      </Form>
      <Divider />
      <div>
        {output?.preventDefaultRender === true || (output?.vectors !== undefined && output.vectors.map(vector => renderOutput(vector, outputValue)))}
      </div>
      <div>
        {output?.render !== undefined && output.render(outputValue, output.vectors)}
      </div>
      <Button onClick={handleClacClick} icon='calculator' primary size='large' />
      <Button onClick={handleResetClick} icon='erase' size='large' />
      {errors !== null && (
        <Message
          error
        >
          <Message.Header>Invalid Inputs:</Message.Header>
          <Message.List>
            {errors.map((error, index) => (
              <Message.Item key={index}>{error}</Message.Item>
            ))}
          </Message.List>
        </Message>
      )}
    </Segment>
  );
};

export default Calculator;
