import React, { useReducer, useContext } from 'react';
import { Coordinate } from '../Components/Animations/model/Point';
import reducer, {
  TRANSFORM_READY, TRANSFORM_DONE, TRANSFORM_RESET, SET_END_POINT, TOGGLE_SIDEBAR, SELECT_CALCULATOR
} from './reducer';

export type InitialStateType = {
  isTransformReady: boolean
  isTransformDone: boolean
  endPoint: Coordinate
  showSidebar: boolean
  selectedCalculatorIndex: number
  transformReady: () => void
  transformDone: () => void
  transformReset: () => void
  setEndPoint: (coordinate: Coordinate) => void
  toggleSidebar: () => void
  selectCalculator: (selectedIndex: number) => void
}

const initialState: InitialStateType = {
  isTransformReady: false,
  isTransformDone: false,
  endPoint: { x: 0, y: 0 },
  showSidebar: true,
  selectedCalculatorIndex: -1,
  transformReady: () => {},
  transformDone: () => {},
  transformReset: () => {},
  setEndPoint: (coordinate: Coordinate) => {},
  toggleSidebar: () => {},
  selectCalculator: (selectedIndex: number) => {}
};

const AppContext: React.Context<InitialStateType> = React.createContext(initialState);

const AppProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const transformReady = (): void => {
    dispatch({ type: TRANSFORM_READY });
  };

  const transformDone = (): void => {
    dispatch({ type: TRANSFORM_DONE });
  };

  const transformReset = (): void => {
    dispatch({ type: TRANSFORM_RESET });
  };

  const setEndPoint = (coordinate: Coordinate): void => {
    dispatch({ type: SET_END_POINT, payload: { coordinate } });
  };

  const toggleSidebar = (): void => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const selectCalculator = (selectedIndex: number): void => {
    dispatch({ type: SELECT_CALCULATOR, payload: { selectedIndex } });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        transformReady,
        transformDone,
        transformReset,
        setEndPoint,
        toggleSidebar,
        selectCalculator
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = (): InitialStateType => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
