import React from 'react';
import { IVector, displayIndicesLiteral } from '../Vectors/Vector';

type ValidateType = {
  type: 'vector'
  vector: IVector
  value: number[]
} | {
  type: 'ac'
  value: number[]
}

export const validateInputs = (inputs: ValidateType[]): null | React.ReactNode[] => {
  const errors: React.ReactNode[] = [];
  inputs.forEach((input) => {
    if (input.type === 'ac') {
      if (input.value[0] === 0 && input.value[1] === 0) {
        errors.push(<span><i>a</i> and <i>c</i> should be non-zero.</span>);
      } else if (input.value[0] === 0) {
        errors.push(<span><i>a</i> should be non-zero.</span>);
      } else if (input.value[1] === 0) {
        errors.push(<span><i>c</i> should be non-zero.</span>);
      }
    } else {
      if (input.value.every((v) => v === 0)) {
        errors.push(<>At least one vaule in {displayIndicesLiteral(input.vector)} should be non-zero.</>);
      }
    }
  });
  return errors.length === 0 ? null : errors;
};
