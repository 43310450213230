type Keyhkl = 'h' | 'k' | 'l'

type Keyuvw = 'u' | 'v' | 'w'

type KeyUVW = 'U' | 'V' | 'W'

type Keyac = 'a' | 'c'

export type Typehkl = {
  [key in Keyhkl]: number
}

export type Typeuvw = {
  [key in Keyuvw]: number
}

export type TypeUVW = {
  [key in KeyUVW]: number
}

export type Typeac = {
  [key in Keyac]: number
}

export type Sub<T, sub extends string> = {
  [keys in keyof T as `${string & keys}${sub}`]: number
};

export const getInitialhkl = (initialValue: number): Typehkl => ({
  h: initialValue,
  k: initialValue,
  l: initialValue
});

export const getInitialuvw = (initialValue: number): Typeuvw => ({
  u: initialValue,
  v: initialValue,
  w: initialValue
});

export const getInitialUVW = (initialValue: number): TypeUVW => ({
  U: initialValue,
  V: initialValue,
  W: initialValue
});

export const getInitialac = (initialValue: number): Typeac => ({
  a: initialValue,
  c: initialValue
});
