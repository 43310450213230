import { IPoint, Point } from './Point';
import { OpenAnimationConfigType } from '../OpenAnimation/openAnimationConfig';

interface IDrawHexBackground {
  draw: () => void
  cancelDraw: () => void
}

export class DrawHexBackground implements IDrawHexBackground {
  private readonly width: number;
  private readonly height: number;
  private readonly pointsToDraw: IPoint[] = [];
  protected readonly points: IPoint[][];
  private readonly ctx: CanvasRenderingContext2D;

  constructor (width: number, height: number, ctx: CanvasRenderingContext2D, config: OpenAnimationConfigType) {
    this.ctx = ctx;
    this.width = width;
    this.height = height;
    const { strokeStyle, centerDistance } = config;
    ctx.strokeStyle = strokeStyle;
    const maxWidth = Math.ceil(width / centerDistance);
    const maxHeight = Math.floor((2 / Math.sqrt(3)) * height / centerDistance);
    this.points = new Array(maxWidth + 1).fill(0).map(_ => new Array(maxHeight + 1));
    for (let i = 0; i <= maxWidth; i++) {
      for (let j = 0; j <= maxHeight; j++) {
        const canvasPoints = {
          x: j % 2 === 1 ? (i + 0.5) * centerDistance : i * centerDistance,
          y: (j / 2 * Math.sqrt(3)) * centerDistance
        };
        const point = new Point(ctx, { x: canvasPoints.x, y: canvasPoints.y }, config);
        this.points[i][j] = point;
      };
    }
    for (let i = 0; i <= maxWidth; i++) {
      for (let j = 0; j <= maxHeight; j++) {
        if (this.points[i][j] === undefined) continue;
        const neighboursIndices = [
          [1, 0], [1, 1], [0, 1], [-1, 0], [-1, -1], [0, -1]
        ];
        const neighbours: IPoint[] = [];
        neighboursIndices.forEach(([x, y]) => {
          if (i + x >= 0 && i + x <= maxWidth && j + y >= 0 && j + y <= maxHeight && this.points[i + x][j + y] !== undefined) {
            neighbours.push(this.points[i + x][j + y]);
          }
        });
        this.points[i][j].setNeighbours(neighbours);
      };
    };
    const midPoint = {
      x: Math.floor(maxWidth / 2),
      y: Math.floor(maxHeight / 2)
    };
    this.pointsToDraw.push(this.points[midPoint.x][midPoint.y]);
  };

  draw (): void {
    let delay = 0;
    this.ctx.beginPath();
    let pointsDrawn = 0;
    while (this.pointsToDraw.length > 0) {
      const point = this.pointsToDraw.pop();
      if (point === undefined) break;
      if (point.getIsDraw()) continue;
      const randomDelay = Math.random() * 2000;
      point.draw(delay + randomDelay);
      pointsDrawn++;
      point.setIsDrawn();
      const neighbours = point.getNeighbours();
      for (let i = 0; i < neighbours.length; i++) {
        const neighbour = neighbours[i];
        if (neighbour?.getIsDraw()) continue;
        this.pointsToDraw.push(neighbour);
      }
      delay += Math.ceil(400 / (10 ** pointsDrawn + 20));
    }
  }

  cancelDraw (): void {
    this.points.forEach((arr) => arr.forEach((point) => { point.cancelDraw(); }));
    this.ctx.clearRect(0, 0, this.width, this.height);
  };
};
